import { Image } from "./image";
import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <Carousel
        useKeyboardArrows
        swipeable
        showThumbs={false}
        infiniteLoop
        centerMode
        showIndicators={false}
        showStatus={false}
        verticalSwipe={'natural'}
        renderArrowPrev={(clickHandler, hasPrev, label) => {
          return <a
            onClick={clickHandler}
            className="btn btn-custom a"
          >
            <i className={'fa-solid fa-arrow-left'} />
          </a>
        }}
        renderArrowNext={(clickHandler, hasPrev, label) => {
          return <a
            onClick={clickHandler}
            className="btn btn-custom b"
          >
            <i className={'fa-solid fa-arrow-right'} />
          </a>
        }}>
        <div className="container">
          <div className="section-title">
            <div className="col-md-6">
              <img
                className="sekita"
                src={props?.data?.bgimg}
              />
            </div>
            <div className="col-md-6">

              <h2>O sistema Teigro otimiza operações, reduz perdas e aumenta a qualidade dos seus produtos.</h2>
              <p>
                A Sekita e Alho da Serra, duas empresas renomadas do setor agrícola, uniram forças para cultivar as melhores sementes de alho do mercado, utilizando 100% do sistema Teigro de rastreabilidade e monitoramento da cadeia de frio.
                <br />
                <br />
                Com a Teigro, as empresas puderam monitorar desde a plantação até a entrega das sementes de alho aos seus clientes, garantindo uma rastreabilidade de ponta a ponta.
                <br />
                <br />
                Graças ao sucesso da parceria entre a Sekita e Alho da Serra e a Teigro, as empresas puderam expandir seus negócios, ganhando reconhecimento no mercado e aumentando sua base de clientes satisfeitos.
              </p>
            </div>
          </div>
          {/* <div className="row">
          <div className="portfolio-items">
            {props?.data?.data
              ? props.data.data.map((d, i) => (
                <div
                  key={`${d.title}-${i}`}
                  className="col-sm-6 col-md-4 col-lg-4"
                >
                  <Image
                    className=""
                    title={d.title}
                    largeImage={d.largeImage}
                    smallImage={d.smallImage}
                  />
                </div>
              ))
              : "Loading..."}
          </div>
        </div> */}
        </div></Carousel>

      <div className="container fullwid precisar">
        <div className="section-title">
          <div className="row">
            <div className="col-md-12">
              <h2>  Ajudamos as suas equipes a gerir processos<br />e tomar decisões precisas.</h2>
            </div>
          </div>
          <div className="row rajudamos">
            <div className="col-md-5">
              <Image
               title={'O controle adequado da temperatura, umidade e CO2 no ambiente de cultivo é fundamental para criar condições ideais para o crescimento e desenvolvimento dos bulbos de alho, maximizando assim a produção e a qualidade das colheitas.'}
                largeImage={props?.data?.data[0]?.largeImage}
                smallImage={props?.data?.data[0]?.largeImage}
              />
            </div>
            <div className="col-md-6 col-md-offset-1 ajudamos">
              <h1>Armazenamento</h1>
              <p>Nosso painel permite conferir em tempo real a umidade e temperatura das câmeras frias.</p>
              <a className="btn btn-custom btn-sm">Ver exemplos</a>
            </div>
          </div>
          <div className="row rajudamos">
            <div className="col-md-5 ajudamos">
              <h1>IVD</h1>
              <p>É importante ter dados precisos para que os gráficos de IVD sejam uma ferramenta valiosa na tomada de decisões.</p>
              <a className="btn  btn-sm btn-custom">Ver exemplos</a>
            </div>
            <div className="col-md-5 col-md-offset-1">
              <Image
              title={'O Índice Visual de Superação de Dormência (IVD) é um cálculo utilizado para avaliar a capacidade de brotação de bulbos de alho.                           Esse índice indica o tamanho da gema de brotação e valores iguais ou superiores a 70% indicam que o alho-semente está adequado para o plantio.'}
                largeImage={props?.data?.data[0]?.smallImage}
                smallImage={props?.data?.data[0]?.smallImage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
