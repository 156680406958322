import api from "./axios";

export function sendMail(mail) {
  return api.post("/apis/send-mail", {
    ...mail,
    token: process.env.REACT_APP_API_TOKEN
  }).then((response) => {
    return response.data;
  }).catch((error) => {
    return error.response.data;
    });
}