import React from "react";
import Lottie from "lottie-react";
import blockchain from "../lottie/blockchain.json";
export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6" style={{
    maxHeight: '700px'}
}>
            <Lottie animationData={blockchain} loop={true} />
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="row">
              <div className="about-text">
                <h2>Sobre nós</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-md-6">
                <h3>Blockchain</h3>
                <div className="list-style">
                  <ul>
                    <li>{props.data ? props.data.blockchain : "loading..."}</li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">
                <h3>Inteligencia artificial</h3>
                <div className="list-style">
                  <ul>
                    <li>{props.data ? props.data.ia : "loading..."}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="row" >

              <div className="col-xs-12 col-md-6">
                <h3>Coldchain</h3>
                <div className="list-style">
                  <ul>

                    <li>{props.data ? props.data.coldchain : "loading..."}</li>
                  </ul>
                </div>
              </div>
              <div className="col-xs-12 col-md-6">

                <h3>IoT</h3>
                <div className="list-style">
                  <ul>
                    <li>{props.data ? props.data.iot : "loading..."}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>
    </div>
  );
};
