import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
export const Sensores = (props) => {
  return (
    <div id="sensores" className="text-center">
      <div className="container">

        <div className="row">
        <img className="bgimg" src={props?.data?.bgimg}/>
          <div className="col-md-4 sensordiv section-title">
            <h1>Soluções<br />Teigro</h1>
            <p>Dados em tempo real com nossos sensores em campo.</p>
            <ul>
              <li><i className={'fa-solid fa-check'} />100% Preciso</li>
              <li><i className={'fa-solid fa-check'} />Dados em tempo real</li>
              <li><i className={'fa-solid fa-check'} />Redução de Prejuízos</li>
            </ul>
            <a
                  href="#features"
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Agende uma demonstração
                </a>
          </div>
          <div className="col-md-7 col-md-offset-1 carousell section-title">
          <Carousel 
          useKeyboardArrows
          swipeable
          infiniteLoop
          centerMode
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          verticalSwipe={'natural'}
          renderArrowPrev={(clickHandler, hasPrev, label) => {
            return <a
            onClick={clickHandler}
            className="btn btn-custom a"
          >
           <i className={'fa-solid fa-arrow-left'} />
          </a> 
          }}
          renderArrowNext={(clickHandler, hasPrev, label) => {
            return <a
            onClick={clickHandler}
            className="btn btn-custom b"
          >
           <i className={'fa-solid fa-arrow-right'} />
          </a> 
          }}
          
          autoFocus transitionTime={1000} autoPlay>
                
                {props?.data?.data
              ? props.data.data.map((d, i) => (
                <div className="contain" key={`${d.title}-${i}`}>
                  <div className="titleholder">
                    <div className="imageholder">
                      <video autoPlay  muted width="100%" height="100%" style={{ objectFit: 'cover' }} >
                      <source src={d.video} type="video/mp4" />
                    </video>
                    </div>
                    <h3>{d.title}</h3>
                  </div>
                  
                
                </div>
              ))
              : <></>}

            </Carousel>
           
          </div>

        </div>
      </div>
    </div>
  );
};
