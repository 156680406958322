import React, { useState, useEffect, useRef } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Sensores } from "./components/sensores";
import { About } from "./components/about";
import { Services } from "./components/services";
import { AlhoSucesso } from "./components/alhosucesso";
import { Gallery } from "./components/gallery";
import { Contact } from "./components/contact";
import { Controle } from "./components/controle";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const ref = useRef(null);
  const [landingPageData, setLandingPageData] = useState({});
  
  const scrollToElement = () => {
    ref.current?.scrollIntoView({ behavior: "instant" });
  };

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  useEffect(() => {
    scrollToElement();
  }, []);

  return (
    <div ref={ref}>
      {landingPageData && (
        <>
          <Navigation data={landingPageData.Navigation} />
          <Header data={landingPageData.Header} />
          <Sensores data={landingPageData.Sensores} />
          <AlhoSucesso data={landingPageData.AlhoSucesso} />
          <About data={landingPageData.About} />
          <Services data={landingPageData.Services} />
          <Controle data={landingPageData.Controle} />
          <Gallery data={landingPageData.Gallery} />
          <Contact data={landingPageData.Contact} />
        </>
      )}
    </div>
  );
};

export default App;
