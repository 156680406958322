import { useState } from "react";
import Input from 'react-phone-number-input/input'
import React from "react";
import { sendMail } from "../store/requests";
import toast, { Toaster } from 'react-hot-toast';

const initialState = {
  name: "",
  email: "",
  phone: "",
  avaliableDate: "",
  avaliableTime: "",
};
export const Contact = (props) => {
  const [{ name, email, phone, avaliableDate, avaliableTime }, setState] = useState(initialState);

  const handleChange = (e) => {
    console.log(e);
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handlePhoneChange = (e) => {
    console.log(e);
    setState((prevState) => ({ ...prevState, phone: e }));
  }

  const formatPhone = (p) => {
    let r = p.replace(/\D/g, "");
    r = r.replace(/^0/, "");

    if (r.length > 11) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
    } else if (r.length > 7) {
      r = r.replace(/^(\d\d)(\d{5})(\d{0,4}).*/, "($1) $2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
    } else if (p.trim() !== "") {
      r = r.replace(/^(\d*)/, "($1");
    }
    return r;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const ph = formatPhone(phone.replace('+55', ''));
    sendMail({ name, email, phone: ph, avaliableDate, avaliableTime }).then((response) => {
      clearState();
      toast.success(response);
    }).catch((error) => {
      clearState();
      toast.error(error);
    });
  };
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row box">
              <div className="section-title">
                <h2>VAMOS CONVERSAR?</h2>
                <p>
                  Preencha o formulário abaixo e entraremos em contato o mais breve possível.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="input-rounded"
                        placeholder="Nome"
                        required
                        onChange={handleChange}
                        value={name}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <Input country="BR" name="phone" value={phone} onChange={handlePhoneChange} className="input-rounded" placeholder="Número de telefone" />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="input-rounded"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={email}
                      />
                      <p className="help-block text-danger"></p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <p className="input-date">Data Disponível</p>
                    <input
                      type="date"
                      id="avaliableDate"
                      name="avaliableDate"
                      className="input-rounded"
                      placeholder="Data Disponível"
                      required
                      onChange={handleChange}
                      min={new Date().toISOString().split("T")[0]}
                      value={avaliableDate}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                  <div className="col-md-6">
                  <p className="input-date">Horário Disponível</p>
                    <input
                      type="time"
                      id="avaliableTime"
                      name="avaliableTime"
                      className="input-rounded"
                      placeholder="Horário Disponível"
                      required
                      onChange={handleChange}
                      value={avaliableTime}
                    />
                    <p className="help-block text-danger"></p>
                  </div>
                </div>
                <div id="success"></div>
                <button type="submit" className="btn btn-custom btn-lg">
                  Enviar Mensagem
                </button>
              </form>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Informações de Contato</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Endereço
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Telefone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
         <Toaster />
        </div>
      </div>
     
    </div>
  );
};
