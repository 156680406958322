import React from "react";
import { Image } from "./image";
export const Navigation = (props) => {
  console.log(props)
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            <img style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
              src={props?.data?.largeImage}
            />

          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#" className="page-scroll">
                Início
              </a>
            </li>
            <li>
              <a href="#sensores" className="page-scroll">
                Soluções
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                Sobre
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                Sucessos
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                Contato
              </a>
            </li>
            <li>
              <a href="https://app.teigro.com/" className="page-scroll">
                Acesso
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
