import React, { useEffect } from 'react';

export const Header = (props) => {
  useEffect(() => {
    const videoElement = document.querySelector('.bg-video');

    const handleVideoEnd = () => {
      videoElement.currentTime = 0;
      videoElement.play();
    };

    const handleUserInteraction = () => {
      videoElement.play();
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleVideoEnd);
      // Iniciar o vídeo após uma interação do usuário
      document.addEventListener('touchstart', handleUserInteraction);
    }

    return () => {
      if (videoElement) {
        videoElement.removeEventListener('ended', handleVideoEnd);
      }
      document.removeEventListener('touchstart', handleUserInteraction);
    };
  }, []);

  return (
    <header id="header">
      <div className="intro">
        {props.data ? (
          <video autoPlay className="bg-video" muted playsInline>
            <source src={props.data.video} type="video/mp4" />
          </video>
        ) : null}
        {/* ... (resto do seu código) */}
      </div>
    </header>
  );
};
