import React from "react";
export const Controle = (props) => {
  return (
    <div id="controle" className="text-center">
     
        <div className="container">

          <div className="row">
            <div className="divbgimg"></div>
            <img className="bgimg" src={props?.data?.bgimg} />
            <div className="col-md-4 col-md-offset-1 carousell section-title">
              <img className="celular" src={props?.data?.celular} />
            </div>

            <div className="col-md-6 sensordiv section-title">
              <h1>Acompanhe o que é<br />mais importante</h1>
              <p>A forma mais eficiente de ter controle sobre a empresa é conhecer bem cada detalhe da operação, nossa inteligência artificial te fará alcançar seus objetivos.</p>
              <ul>
                <li><i className={'fa-solid fa-check'} />Monitore o processo</li>
                <li><i className={'fa-solid fa-check'} />Receba alertas de falhas</li>
                <li><i className={'fa-solid fa-check'} />Acabe com o prejuízo</li>
              </ul>

            </div>

          </div>
        </div>
    </div>
  );
};
