import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
export const AlhoSucesso = (props) => {
  return (
    <div id="alhosucesso" className="text-center">
      <div className="container">

        <div className="row">
          <div className="col-md-4 sensordiv section-title">
            <h1>Especialistas no sucesso do alho</h1>
            <p>Pela técnica do IVD usando um paquímetro preciso. Nossa abordagem inovadora resulta em soluções específicas para a qualidade de alho. Conte conosco para implementação bem-sucedida.</p>

            <a
              href="#features"
              className="btn btn-custom btn-lg page-scroll"
            >
              Leia mais
            </a>
          </div>
          <div className="col-md-8 imagem section-title">
           {props?.data?.bgimg ? <img src={props?.data?.bgimg} /> : <></>}
          </div>
        </div>
      </div>
    </div>
  );
};
